import {alertService} from "@/services/alert/alert.service";
import {emailAuthenticationService} from "@/services/auth/email-auth/email-auth.service";
import LineLoginButton from '../login/guest/line-login-button/LineLoginButton'
import config from "@/config/app.config"

export default {
    components: {
        LineLoginButton
    },
    data() {
        return {
            data: {},
            message: {},
            config: {},
            status: false,
            line_config: {
                response_type: 'code',
                client_id: config.line_api.client_id,
                client_secret: config.line_api.client_secret,
                redirect_uri: config.line_api.callback_uri,
            },
        }
    },
    async mounted() {

    },
    methods: {
        async authenticate$() {
            await alertService.loading();
            this.error = {}
            await emailAuthenticationService.authenticate(this.data).then((response) => {
                this.status = response.data.success;
                this.message = response; 
                alertService.close();
            }).catch((response) => {
                this.message = response;
                alertService.close();
            });
        },
    }

}
