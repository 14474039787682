import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class EmailAuthenticationService {
    authenticate(data) {
        promise =  api.client.post(config.end_point.auth.email_auth, data);
        return promise;
    }
}

export const emailAuthenticationService = new EmailAuthenticationService();
